body {
    margin: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body, h1, p, ul, li {
    margin: 0;
    padding: 0;

}

h1 {
    font-size: 7vw;
}

.subtitle {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New';
    font-style: normal;
    font-size: 4vw;
    justify-self: left;

}

body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    font-size: large;
    font-family: 'Monaco', sans-serif;
    background: linear-gradient(45deg, #f2f2f2, #c8f2ff, #f2f2f2);
    background-size: 300% 300%;
    animation: color-change 10s infinite;
    overflow: hidden;
}

.snap-container::-webkit-scrollbar {
    display: none;
}

.App {
    max-height: 100vh;
    overflow: scroll;
}

header {
    text-align: center;
    margin-bottom: 20px;
    font-size: xx-large;
}

.snap-container {
    scroll-snap-type: y mandatory;
    max-height: 100vh;
    overflow: scroll;
}

.snap-child {
    scroll-snap-align: start;
}

.snap-container, .snap-child {
    overflow: auto; /* or overflow: scroll; */
}

.anchored-title {
    margin-left: 1vw;
}

#landing-section {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95vh;
}

#projects-section {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    background-color: rgb(32, 32, 32);
    color: whitesmoke;
}

#project-card-root {
    margin: 50px 1vw;
    display: flex;
    flex-direction: column;
    width: 98vw;
}

.project-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    margin-bottom: 1vh;
    padding: 20px 20px 1vw 1vw;
    min-height: 10vh;
    vertical-align: center;
    background-color: rgb(44, 44, 44);
    border-radius: 10px;
    border-width: 2px;
    border-style: solid;
    border-color: rgb(66, 66, 66);
    list-style-type: none;
    max-width: min(1200px, 90lvw);
    display: flex;
    flex-wrap: wrap;
}

.arrow {
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 30px;
}

.down {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
}

.scroll-button {
    position: absolute;
    bottom: 0;
    background: none;
    color: inherit;
    border: none;
    padding-top: 50px;
    font: inherit;
    cursor: pointer;
    outline: inherit;
}

.card-content-container {
    width: 50%;
}

.card-thumbnail-container {
    align-items: center;
    justify-content: right;
    overflow-clip-margin: content-box;
    overflow: clip;
    width: 50%;
    border-radius: 5%;
    max-height: 500px;
}

@media (max-width: 600px) {
    .card-content-container, .card-thumbnail-container {
        width: 100%;
    }
}

.project-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.link-button {
    background-color: #3f813a;
    color: #ececec;
    border-color: #282c34;
    margin-top: 20px;
    margin-right: 10px;
    padding: 5px 8px;
    border-radius: 6px;
    display: inline-block;
    border-width: 2px;
    border-style: solid;
    text-decoration: none;
    font-size: 16px;
}

.top-bar {
    position: fixed;
    width: 100svw;
    height: 50px;
    display: flex;
    background-image: linear-gradient(90deg, rgba(19, 19, 19, 0.9), rgb(32, 32, 32, 0.8), rgba(32, 32, 32, 0.0));
    padding-bottom: 15px;
}

.card-thumbnail {
    margin: 0 auto;
    display: block; /* This is optional if the element is not already a block-level element */

    height: 100%;
    max-width: 100%;
    max-height: 100%;
}

.cardTag {
    background-color: #314556;
    margin-top: 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 3px 10px;
    border-radius: 5px;
    display: inline-block;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', serif;
}

.project-card p, .project-card li {
    color: rgb(194, 194, 194);
}

.project-card h2 {
    margin-top: 0;
}

.project-card h4 {
    margin-bottom: 10px;
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    font-size: large;
}

.side-bar {
    position: fixed;
    width: 100%;
    display: flex;
    color: whitesmoke;
}

nav ul li {
    margin: 0 10px;
}

nav ul li a {
    text-decoration: none;
    color: #333;
    font-size: larger;
}

#landing-page li:hover {
    background: linear-gradient(-180deg, #f2f2f2, #aafcff);
    text-decoration: underline;
}

.section {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.fade-in {
    opacity: 0;
    animation: fade-in 1s forwards;
}

.fall-down {
    animation: fall-down 1s forwards;
}

.fall-up {
    animation: fall-up 1s forwards;
}

.fade-in-late {
    opacity: 0;
    animation: fade-in 1s 1s forwards;
}

.fade-in-out {
    opacity: 0;
    animation: fade-in-out 1.5s forwards;
}

.centered-banner {
    position: 'fixed';
    top: '50%';
    left: '50%';
    width: 100%;
    transform: 'translate(-50%, -50%)';
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes fall-down {
    from {
        opacity: 0;
        transform: translateY(-100%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fall-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

.cursor {
    display: inline-block;
    position: relative;
    top: -0.1em;
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',

}

.profile-link {
    padding: 0.3em;
}

.profile-link img {
    height: 2.5em;
}

.cursor::after {
    content: "|";
    opacity: 1;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-in-out {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0.8;
    }
    100% {
        opacity: 0;
    }
}

.typing-animation {
    overflow: hidden;
    width: 0;
    display: inline-block;
    animation: typing 1s steps(14) 1s forwards;
    white-space: nowrap;
    display: inline-block;
    white-space: nowrap;
    margin-right: 0;
}

.email {
    margin-top: 20px;
    font-size: larger;
}

@keyframes typing {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

@keyframes color-change {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}